export default [
    {
        key:"code",
        label:"Codigo",
        thClass:"text-center",
        tdClass:"text-center",
        visible:true   
    },
    {
        key:"image",
        label:"Imagen",
        thClass:"text-center",
        tdClass:"text-center",
        visible:true   
    },
    {
        key:"category",
        label:"Categoria",
        visible:true   
    },
    {
        key:"name",
        label:"Nombre",
        visible:true
    },
    {
        key:"description",
        label:"Descripcion",
        visible:true
    },
    {
        key:"measurement_unit",
        label:"Presentacion",
        thClass:"text-center",
        tdClass:"text-center",
        visible:true   
    },
    {
        key:"stock_min",
        label:"St. minimo",
        thClass:"text-center",
        tdClass:"text-center",
        visible:false   
    },
    {
        key:"current_stock",
        label:"Stock",
        thClass:"text-center",
        tdClass:"text-center",
        visible:true   
    },
    {
        key:"created_by",
        label:"Creado por",
        thClass:"text-center",
        tdClass:"text-center",
        visible:true   
    },
    {
        key:"actions",
        label:"Acciones",
        thClass:"text-center",
        visible:true   
    },
]