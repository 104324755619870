<template>
	<div>
		<b-modal
			ref="modal-almacen-product"
			@hidden="$emit('close')"
			:title="`${form.id ? 'Actualizar producto' : 'Nuevo Producto'}`"
			cancel-title="Cancelar"
			scrollable
			size="lg"
			title-tag="h3"
			no-close-on-backdrop
			no-close-on-esc
		>
			<ValidationObserver ref="formProduct">
				<div class="containerProduct">
					<div>
						<b-form-group label="Imagen">
							<b-avatar
								v-model="form.image"
								class="clickable text-dark w-100 images"
								style="border: 2px dashed var(--dark)"
								ref="previewEl"
								icon="image"
								square
								rounded="lg"
								variant="light"
								:src="form.image"
								:size="heightImage"
								@click="$refs.fileInput.click()"
							/>
							<div class="d-flex justify-content-end" style="margin-top: 5px">
								<span>
									<feather-icon
										size="22"
										icon="XOctagonIcon"
										class="cursor-pointer text-danger mr-50"
										@click="deletePhoto()"
									/>
									<feather-icon
										size="22"
										icon="UploadIcon"
										class="cursor-pointer text-primary"
										@click="$refs.fileInput.click()"
									/>
								</span>
							</div>
							<input
								ref="fileInput"
								type="file"
								accept=".jpeg,.jpg,.png,.heif,.heic"
								@input="pickFile"
								class="d-none"
							/>
						</b-form-group>
					</div>
					<div>
						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<div class="d-flex justify-content-between align-items-center">
									<label for="category_id">Categoría</label>
									<feather-icon
										icon="PlusIcon"
										class="text-success cursor-pointer"
										size="19"
										@click="addCategory"
									/>
								</div>
								<v-select
									input-id="category_id"
									:clearable="false"
									v-model="form.category_id"
									label="name"
									:options="categories"
									:reduce="(cc) => cc.id"
									appendToBody
									:calculatePosition="positionDropdown"
									placeholder="Seleccione una categoria"
								/>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<label for="name">Nombre</label>
								<b-form-input
									id="name"
									:state="errors[0] ? false : null"
									type="text"
									v-model="form.name"
									placeholder="Ingrese nombre del producto"
								/>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<label for="description">Descripción</label>
								<b-form-input
									id="description"
									:state="errors[0] ? false : null"
									type="text"
									v-model="form.description"
									placeholder="ingrese descripcion de producto..."
								/>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider v-slot="{ errors }" rules="required">
							<b-form-group :state="errors[0] ? false : null">
								<div class="d-flex justify-content-between align-items-center">
									<label for="measurement_unit_id">Presentacion</label>
									<feather-icon
										icon="PlusIcon"
										class="text-success cursor-pointer"
										size="19"
										@click="weightUnitModal = true"
									/>
								</div>
								<v-select
									input-id="measurement_unit_id"
									:clearable="false"
									v-model="form.measurement_unit_id"
									label="name"
									:options="weight_units"
									:reduce="(cc) => cc.id"
									appendToBody
									:calculatePosition="positionDropdown"
									placeholder="Seleccione unidad de medida..."
								/>
							</b-form-group>
						</ValidationProvider>
					</div>
				</div>
				<div class="d-flex justify-content-end align-items-center" style="margin-bottom: 5px">
					<div class="d-flex flex-column">
						<b-form-group>
							<div class="d-flex justify-content-between align-items-center">
								<label for="measurement_unit_id">Marcas</label>
								<feather-icon
									icon="PlusIcon"
									class="text-success cursor-pointer"
									size="19"
									@click="addBrand"
								/>
							</div>
							<v-select
								input-id="brands"
								:clearable="false"
								v-model="brand"
								label="name"
								:options="brandsNotSelected"
								:reduce="(cc) => cc.id"
								appendToBody
								@input="addBrandSelected()"
								style="width: 20em"
								:calculatePosition="positionDropdown"
								placeholder="Agregar una marca..."
							/>
						</b-form-group>
					</div>
				</div>
				<div class="table-responsive table-striped border-top-primary border-3">
					<b-table
						:items="brands_selected"
						:fields="fields"
						class="mt-1"
						sticky-header="40vh"
						primary-key="id"
						responsive
						small
						show-empty
						ref="table-brands"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Cargando...</strong>
							</div>
						</template>

						<template #cell(logotype)="data">
							<ViewImage :specimen="data.item" />
						</template>

						<template #cell(name)="data">
							<div class="d-flex d-code justify-content-center align-items-center">
								<span class="d-code">{{ data.item.name }}</span>
							</div>
						</template>

						<template #cell(description)="data">
							<div class="d-flex d-code justify-content-center align-items-center">
								<span class="d-code">{{ data.item.description ? data.item.description : "---" }}</span>
							</div>
						</template>

						<template #cell(actions)="data">
							<div class="d-flex d-code justify-content-center">
								<b-button
									@click="uncheckBrand(data.item.id)"
									variant="danger"
									class="btn-icon ml-1 btn-sm"
								>
									<feather-icon icon="Trash2Icon" />
								</b-button>
							</div>
						</template>
					</b-table>
				</div>
				<!-- <b-col md="4">
							<ValidationProvider v-slot="{ errors }">
								<b-form-group :state="errors[0] ? false : null">
									<label for="stock_min">Stock minimo</label>
									<b-form-input
										id="stock_min"
										:state="errors[0] ? false : null"
										type="number"
										v-model="form.stock_min"
										placeholder="Ingrese stock minimo..."
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col> -->
			</ValidationObserver>

			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
				<b-button v-if="form.id == null" variant="primary" @click="registerProduct">Guardar</b-button>
				<b-button v-if="form.id != null" variant="primary" @click="updateProduct">Guardar</b-button>
			</template>
		</b-modal>

		<WeightUnitModal
			v-if="weightUnitModal"
			:info="{ id: null, name: null }"
			:type="'generic'"
			@close="weightUnitModal = false"
			@registered="getWeightUnits(), (weightUnitModal = false)"
		/>

		<CategoriaModal
			v-if="categoryModal"
			:info="{ id: null, name: null }"
			@close="categoryModal = false"
			@registered="getCategories(), (categoryModal = false)"
		/>
		<BrandsModal
			v-if="showModalBrands"
			:info="{ id: null, name: null }"
			@close="showModalBrands = false"
			@registered="getBrands(), (showModalBrands = false)"
		/>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import { ValidationProvider } from "vee-validate"
import WeightUnitModal from "@/views/brain/administrative/views/weight-units/components/WeightUnitModal.vue"
import CategoriaModal from "@/views/brain/administrative/views/categories/components/CategoriesModal.vue"
import CategoriesService from "@/views/brain/administrative/views/categories/services/categories.service.js"
import WeightService from "@/views/brain/administrative/views/weight-units/services/weight.service.js"
import WarehouseService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
import Fields from "@/views/amg/management/views/warehouse/data/fields-brands-data"
import BrandsModal from "@/views/brain/administrative/views/branch/components/BrandsModal.vue"
import BrandsService from "@/views/brain/administrative/views/branch/services/brands.service.js"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	props: {
		info: {
			required: true,
		},
	},
	directives: { Ripple },
	components: { ViewImage, WeightUnitModal, CategoriaModal, BrandsModal },
	mixins: [modalMixin],
	data() {
		return {
			form: {
				id: null,
				category_id: null,
				name: null,
				description: null,
				measurement_unit_id: null,
				image: null,
				file: null,
				// stock_min: 1,
			},
			categories: [],
			weight_units: [],
			brands: [],
			brands_selected: [],
			last_brands_selected: [],
			brand: 0,
			fields: Fields,
			modal: {
				category: {},
				weightUnit: {},
			},
			categoryModal: false,
			weightUnitModal: false,
			showModalBrands: false,
		}
	},
	async mounted() {
		this.toggleModal("modal-almacen-product")
	},
	async created() {
		try {
			await Promise.all([this.getCategories(), this.getWeightUnits(), this.getBrands()])
			if (this.info.id != null) {
				this.form.id = this.info.id
				this.form.category_id = this.info.category_id
				this.form.name = this.info.name
				this.form.description = this.info.description
				this.form.measurement_unit_id = this.info.measurement_unit_id
				this.form.image = this.info.image
				this.last_brands_selected = this.info.brands
				this.brands_selected = this.discoverBrands()
				// this.form.stock_min = this.info.stock_min
			}
		} catch (error) {
			console.log("error", error)
		}
	},
	computed: {
		heightImage() {
			return ["md", "lg", "xl"].includes(this.currentBreakPoint) ? "18rem" : "15rem"
		},
		brandsNotSelected() {
			let brands_id = this.brands_selected.map((i) => i.id)
			let not_selected = this.brands.filter((index) => {
				return !brands_id.includes(index.id)
			})
			return not_selected
		},
	},
	methods: {
		async registerProduct() {
			const validate = await this.$refs.formProduct.validate()
			if (!validate) return

			const { isConfirmed } = await this.showConfirmSwal({ text: `Registrar producto ${this.form.name}.` })
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const formData = new FormData()
				formData.append("category_id", this.form.category_id)
				formData.append("name", this.form.name)
				formData.append("description", this.form.description)
				formData.append("measurement_unit_id", this.form.measurement_unit_id)
				formData.append("image", this.form.file)
				formData.append("brands", JSON.stringify(this.brands_selected.map((i) => i.id)))
				const { data } = await WarehouseService.registerProduct(formData)

				this.showSuccessToast(data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		discoverBrands() {
			if (!this.last_brands_selected.length) return []
			let brands_id = this.last_brands_selected
			return this.brands.filter((index) => {
				return brands_id.includes(index.id)
			})
		},

		addBrandSelected() {
			let selected_id = this.brand
			const selected_item = this.brands.find(({ id }) => id == selected_id)
			this.brands_selected.push(selected_item)
			this.brand = 0
		},

		uncheckBrand(brand_id) {
			let index = this.brands_selected.findIndex((index) => index.id === brand_id)
			this.brands_selected.splice(index, 1)
			// console.log(index);
		},

		async getBrands() {
			try {
				const { data } = await BrandsService.getBrandsSelect()
				this.brands = data
			} catch (error) {
				throw error
			}
		},
		async updateProduct() {
			const validate = await this.$refs.formProduct.validate()
			if (!validate) return

			const { isConfirmed } = await this.showConfirmSwal({ text: `Actualizar producto ${this.form.name}.` })
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const formData = new FormData()
				formData.append("category_id", this.form.category_id)
				formData.append("name", this.form.name)
				formData.append("description", this.form.description)
				formData.append("measurement_unit_id", this.form.measurement_unit_id)
				formData.append("image", this.form.file)
				formData.append("brands", JSON.stringify(this.brands_selected.map((i) => i.id)))
				const { data } = await WarehouseService.updateProduct(this.form.id, formData)
				this.showSuccessToast(data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getCategories() {
			try {
				const { data } = await CategoriesService.getCategoriesForSelect()
				this.categories = data.data
			} catch (error) {
				throw error
			}
		},
		async getWeightUnits() {
			try {
				const datas = {
					type: "generic",
				}
				const { data } = await WeightService.getWeightForSelect(datas)
				this.weight_units = data
			} catch (error) {
				throw error
			}
		},

		addCategory() {
			this.categoryModal = true
		},

		addBrand() {
			this.showModalBrands = true
		},

		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},

		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files

			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (!["jpeg", "jpg", "png"].includes(typeOfFile)) {
				this.showToast(
					"warning",
					"top-right",
					"Formato de archivo no valido",
					"CheckIcon",
					"Los formatos aceptados son .jpeg, .jpg y .png ."
				)
				return
			}
			if (typeOfFile === "heic" || typeOfFile === "heif") {
				this.isPreloading()
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				)
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage)
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
					this.form.file = newFile
					this.form.image = url
					this.isPreloading(false)
				})
			} else {
				this.form.file = file[0]
				if (file && file[0]) {
					let reader = new FileReader()
					reader.onload = (e) => {
						this.form.image = e.target.result
					}
					reader.readAsDataURL(file[0])
					this.form.file = file[0]
				}
			}
		},
		deletePhoto() {
			this.form.image = null
			this.form.file = null
		},
	},
}
</script>

<style scoped>
.containerProduct {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}

@media screen and (max-width: 768px) {
	.containerProduct {
		grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos con un ancho máximo de 768px */
	}
}

.images {
	height: 16.5rem;
}

.image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

@media screen and (max-width: 768px) {
	.images {
		height: 18rem;
	}
}
</style>
