<template>
	<div>
		<!-- <div class="card align-items-center px-1 py-0 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" roles="tabslits">
				<b-nav-item
					v-for="(tab, index) in tabs"
					:key="index"
					class="x-tab-width"
					link-classes="px-1 justify-content-between w-100"
					:active="tab.status == statusTab"
					@click="changeTab(tab.status)"
				>
					<span>{{ tab.name }}</span>
				</b-nav-item>
			</ul>
		</div> -->

		<b-card no-body class="mb-1">
			<div class="m-2">
				<!-- Paginator -->
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>

					<!-- Search -->
					<b-col cols="12" xl="8" class="mt-1">
						<div class="container-btns-page-specimens d-flex justify-content-end">
							<div class="d-flex">
								<b-form-input
									v-model="search_input"
									class="d-inline-block mr-1"
									placeholder="Nombre..."
									debounce="500"
									@keyup.enter="resetSearch"
								/>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="showFields"
					sticky-header="75vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					striped
					show-empty
					ref="table-warehouse"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>

					<template #cell(image)="data">
						<ViewImage
							:specimen="{
								id: data.item.id,
								url: data.item.image,
								description: data.item.description,
							}"
						/>
					</template>

					<template #cell(created_by)="data">
						<div class="d-flex flex-column justify-content-center aling-items-center">
							<span>{{ data.item.created_by }}</span>
							<span>{{ data.item.created_at | myGlobalDayWithHour }}</span>
						</div>
					</template>

					<template #cell(actions)="data">
						<div class="d-flex justify-content-center align-items-center">
							<b-button
								variant="warning"
								class="btn-icon btn-sm d-flex align-items-center"
								@click="editProduct(data.item)"
							>
								<feather-icon icon="EditIcon" />
							</b-button>

							<b-button
								@click="showList(data.item)"
								variant="info"
								class="btn-icon btn-sm ml-1 d-flex align-items-center"
							>
								<feather-icon icon="ListIcon" />
							</b-button>

							<b-button
								@click="showKardex(data.item)"
								variant="primary"
								class="btn-icon btn-sm ml-1 d-flex align-items-center"
							>
								<feather-icon icon="ClipboardIcon" />
							</b-button>

							<b-button
								@click="deleteProducts(data.item.id, data.item.name)"
								variant="danger"
								class="btn-icon btn-sm ml-1 d-flex align-items-center"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</div>
		</b-card>

		<ProductModal
			v-if="productModal"
			:info="modal.product"
			@close="productModal = false"
			@registered="resetSearch(), (productModal = false)"
		/>

		<AlmOperationModal
			v-if="almOperationModal"
			@close="almOperationModal = false"
			@registered="resetSearch(), (almOperationModal = false)"
		/>

		<AlmListModal v-if="almListModal" @close="almListModal = false" :info="modal.list" />

		<KardexModal v-if="showKardexModal" @close="showKardexModal = false" :info="modal.kardex" />
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import Fields from "@/views/amg/management/views/warehouse/data/fields.data.js"
import WarehouseService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
import ProductModal from "@/views/amg/management/views/warehouse/components/ProductModal.vue"
import AlmOperationModal from "@/views/amg/management/views/warehouse/components/AlmOperationModal.vue"
import AlmListModal from "@/views/amg/management/views/warehouse/components/AlmListModal.vue"
import KardexModal from "@/views/amg/management/views/warehouse/components/KardexModal.vue"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	directives: {
		Ripple,
	},
	components: {
		ProductModal,
		AlmOperationModal,
		AlmListModal,
		KardexModal,
		ViewImage,
	},
	props: {},
	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			search_input: "",
			filterController: false,
			fields: Fields,
			modal: {
				product: {},
				operation: {},
				list: {},
				kardex: {},
			},
			showKardexModal: false,
			productModal: false,
			almOperationModal: false,
			almListModal: false,
		}
	},
	computed: {
		showFields() {
			return this.fields.filter((f) => f.visible)
		},
	},
	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input,
				responsible_id: null,
				page: ctx.currentPage,
				perpage: ctx.perPage,
				status: this.statusTab,
			}

			try {
				const { data } = await WarehouseService.getProducts(params)
				this.start_page = data.data.from
				this.to_page = data.data.to
				this.total_data = data.data.total
				this.current_page = data.data.current_page
				return data.data.data
			} catch (error) {
				console.log("error: ", error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		async deleteProducts(id, name) {
			const confirm = await this.showConfirmSwal({
				text: `Eliminar producto ${name}`,
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const { data } = await WarehouseService.deleteProduct(id)
				this.showSuccessToast(data.message)
				this.resetSearch()
			} catch (error) {
				this.showErrorSwal(a, error.response?.data)
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		showKardex(item) {
			this.showKardexModal = true
			this.modal.kardex = item
		},

		createProduct() {
			this.productModal = true
			this.modal.product = {}
		},

		editProduct(item) {
			this.productModal = true
			this.modal.product = item
		},

		showOperation() {
			this.almOperationModal = true
			this.modal.operation = {}
		},

		showList(item) {
			this.almListModal = true
			this.modal.list = item
		},
		resetSearch() {
			this.$refs["table-warehouse"].refresh()
		},
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}
</style>
