import axios from "@/axios"

const url = "/api/management/warehouse"

class WarehouseService {
	async getProducts(params) {
		try {
			const data = await axios.get(`${url}/get-products`, { params })
			return data
		} catch (error) {
			throw error
		}
	}

    async registerProduct(params){
        try {
            const data = await axios.post(`${url}/save-product`,params)
            return data
        } catch (error) {
            throw error
        }
    }

	async getBrandsByProduct(id){
        try {
            const { data } = await axios.get(`${url}/product/${id}/brands`)
            return data
        } catch (error) {
            throw error
        }
    }
	
    async updateProduct(id, params){
        try {
            const data = await axios.post(`${url}/update-product/${id}`,params)
            return data
        } catch (error) {
            throw error
        }
    }

    async searchProducts(params){
        try {
            const data = await axios.get(`${url}/search-products`, {params})
            return data
        } catch (error) {
            throw error
        }
    }

    async registerInputStock(params){
        try {
            const data = await axios.post(`${url}/operations/inputs/save`,params)
            return data
        } catch (error) {
            throw error
        }
    }

    async registerOutputStock(params){
        try {
            const data = await axios.post(`${url}/operations/outputs/save`,params)
            return data
        } catch (error) {
            throw error
        }
    }

	async deleteProduct(id) {
		try {
			const data = await axios.delete(`${url}/delete-product/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async getProductById(id, params) {
		try {
			const data = await axios.get(`${url}/product/${id}/lotes`, {params})
			return data.data
		} catch (error) {
			throw error
		}
	}

    async getTrackingOutputs(product_input_id){
        try {
			const data = await axios.get(`${url}/operations/inputs/${product_input_id}/balance-tracking`)
			return data
		} catch (error) {
			throw error
		}
    }

    async getKardex(params){
        try {
            const data = await axios.get(`${url}/product/${params.product_id}/kardex`,{params})
            return data
        } catch (error) {
            throw error
        }
    }
	

}

export default new WarehouseService()
