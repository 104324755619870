<template>
	<div>
		<b-modal
			ref="modal-almacen-operation"
			@hidden="$emit('close')"
			:title="`Nueva operacion`"
			cancel-title="Cancelar"
			size="xlg"
			title-tag="h3"
			no-close-on-backdrop
			no-close-on-esc
		>
			<div>
				<ValidationObserver ref="formAlmOperation">
					<b-row>
						<b-col md="6" lg="4">
							<b-form-group>
								<label for="name">Codigo</label>
								<b-form-input
									id="code"
									type="text"
									v-model="form.code"
									placeholder="Codigo no generado"
									readonly
								/>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="category_id">Tipo</label>
									<v-select
										input-id="type"
										:clearable="false"
										v-model="form.type"
										label="label"
										:options="operationTypes"
										:reduce="(cc) => cc.id"
										appendToBody
										:calculatePosition="positionDropdown"
										placeholder="Seleccione una categoria"
										@input="changeType"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<b-col md="6" lg="4" v-if="form.type === 'input'">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="category_id">Proveedor</label>
									<v-select
										v-model="form.provider"
										input-id="type"
										:clearable="false"
										label="company_name"
										:options="providers"
										:reduce="(cc) => cc.id"
										appendToBody
										:calculatePosition="positionDropdown"
										placeholder="Seleccione un proveedor"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>

						<!-- <b-col md="6" lg="4" v-if="form.type === 'input'">
					
						</b-col> -->

						<b-col md="6" lg="4">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="date">
										Fecha
										<span class="text-danger">(*)</span>
									</label>
									<flat-pickr
										id="date"
										v-model="form.date"
										class="form-control bg-transparent"
										placeholder="Elige una fecha"
										:class="errors[0] ? 'border-danger' : 'mb-05'"
										:config="{
											enableTime: true,
											altFormat: 'm/d/Y',
											altInput: true,
											dateFormat: 'Y-m-d',
										}"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
						<b-col md="6" lg="4">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<label for="responsible_id">
										Responsable
										<span class="text-danger">(*)</span>
									</label>
									<v-select
										input-id="type"
										:clearable="false"
										v-model="form.responsible_id"
										label="name"
										:options="responsibles"
										:reduce="(cc) => cc.id"
										appendToBody
										:calculatePosition="positionDropdown"
										placeholder="Seleccione responsable..."
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
					</b-row>

					<div class="d-flex justify-content-between align-items-center" style="margin-bottom: 5px">
						<h5 class="mb-0">Productos:</h5>
						<b-button variant="success" class="btn-icon btn-sm" @click="addProduct">
							<feather-icon icon="PlusIcon" />
						</b-button>
					</div>

					<div class="border-top-primary border-3">
						<div class="custom-scroll mt-1">
							<div>
								<div class="d-flex pt-1">
									<span :class="{ 'custom-code': isInput, 'custom-code-out': !isInput }">Codigo</span>
									<span :class="{ 'custom-product': isInput, 'custom-product-out': !isInput }">
										Producto
									</span>

									<span class="custom-measure_unit">Marca</span>
									<span class="custom-measure_unit" v-if="isInput">Fecha expiracion</span>
									<span
										:class="{ 'custom-measure_unit': isInput, 'custom-measure_unit-out': !isInput }"
									>
										Presentacion
									</span>

									<span
										:class="{ 'custom-measure_unit': isInput, 'custom-measure_unit-out': !isInput }"
									>
										Unid. medida
									</span>
									<span
										v-if="!isInput"
										:class="{ 'custom-values': isInput, 'custom-values-out': !isInput }"
									>
										Stock
									</span>
									<span :class="{ 'custom-values': isInput, 'custom-values-out': !isInput }">
										Cantidad
									</span>
									<span class="custom-values" v-if="false">Total</span>

									<span class="custom-values" v-if="isInput">Precio unitario</span>
									<span class="custom-values" v-if="isInput">Precio</span>
								</div>
							</div>

							<div
								v-if="getProducts.length == 0"
								class="d-flex justify-content-center align-items-center"
								style="min-height: 150px; max-height: 300px"
							>
								<h4>No hay productos agregados</h4>
							</div>
							<div v-else>
								<template v-for="(product, key) in getProducts">
									<div :key="key" style="padding-top: 1px">
										<div class="d-flex">
											<!-- CODE -->
											<ValidationProvider v-slot="{ errors }" rules="required">
												<b-form-group
													:class="{ 'custom-code': isInput, 'custom-code-out': !isInput }"
												>
													<b-form-input
														:id="'code' + key"
														type="text"
														v-model="product.code"
														placeholder="Codigo..."
														readonly
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- PRODUCT ID -->
											<ValidationProvider v-slot="{ errors }" rules="required">
												<b-form-group
													:state="errors[0] ? false : null"
													:class="{
														'custom-product': isInput,
														'custom-product-out': !isInput,
													}"
												>
													<v-select
														:id="'product_id' + key"
														v-model="product.prod"
														:options="filteredOptions"
														append-to-body
														placeholder="Buscar..."
														:calculate-position="positionDropdown"
														label="name"
														@input="selectHandler(key)"
														@search="searchItems"
														style="width: 100%"
													/>
												</b-form-group>
											</ValidationProvider>

											<ValidationProvider v-slot="{ errors }" rules="required">
												<b-form-group
													:state="errors[0] ? false : null"
													class="custom-measure_unit"
												>
													<!-- <v-select
														v-model="product.brand"
														input-id="type"
														:clearable="false"
														label="name"
														:options="brands"
														:reduce="(cc) => cc.id"
														appendToBody
														:calculatePosition="positionDropdown"
													/> -->
													<v-select
														:id="'product_id' + key"
														v-model="product.brand"
														:options="product.brands"
														input-id="name"
														:reduce="(cc) => cc.id"
														:clearable="false"
														append-to-body
														@input="changeBrand(key)"
														placeholder="Seleccione una marca..."
														:calculate-position="positionDropdown"
														label="name"
													/>
												</b-form-group>
											</ValidationProvider>

											<b-form-group class="custom-measure_unit" v-if="isInput">
												<flat-pickr
													id="date"
													v-model="product.expiration_date"
													class="form-control bg-transparent"
													placeholder="Vencimiento"
													:disabled="!product.brand"
													:config="{
														enableTime: true,
														altFormat: 'm/d/Y',
														altInput: true,
														dateFormat: 'Y-m-d',
														disable: [disableDates],
													}"
													@input="changeExpirationDate(key)"
												/>
											</b-form-group>

											<!-- MEASURE UNIT -->
											<ValidationProvider>
												<b-form-group
													:class="{
														'custom-measure_unit': isInput,
														'custom-measure_unit-out': !isInput,
													}"
												>
													<b-form-input
														:id="'measure_unit' + key"
														type="text"
														v-model="product.measure_unit"
														placeholder="Codigo..."
														readonly
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- packagings -->

											<ValidationProvider v-slot="{ errors }" rules="required">
												<b-form-group
													:state="errors[0] ? false : null"
													:class="{
														'custom-measure_unit': isInput,
														'custom-measure_unit-out': !isInput,
													}"
												>
													<v-select
														:id="'product_id' + key"
														v-model="product.packaging"
														:options="product.packagings"
														append-to-body
														placeholder="Seleccione..."
														:calculate-position="positionDropdown"
														@input="changePackagind(key)"
														label="presentation"
														:disabled="!product.brand"
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- CURRENT STOCK -->
											<ValidationProvider v-if="!isInput">
												<b-form-group
													:class="{ 'custom-values': isInput, 'custom-values-out': !isInput }"
												>
													<b-form-input
														:id="'current_stock' + key"
														:class="{
															'border-danger':
																product.id != null && !product.current_stock > 0,
														}"
														type="number"
														v-model="product.current_stock"
														placeholder="Stock..."
														:readonly="true"
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- QUANTITY -->
											<ValidationProvider>
												<b-form-group
													:class="{ 'custom-values': isInput, 'custom-values-out': !isInput }"
												>
													<b-form-input
														:id="'count' + key"
														type="number"
														v-model="product.count"
														placeholder="Cantidad..."
														@input="getPriceTotal(key)"
														:readonly="
															(!product.packaging && isInput) ||
															(!isInput && !product.current_stock > 0)
														"
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- Total -->
											<ValidationProvider v-if="false">
												<b-form-group class="custom-values" v-if="isInput">
													<b-form-input
														:id="'quantity' + key"
														type="number"
														v-model="product.total"
														placeholder="Total..."
														readonly
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- Costo unitario -->
											<ValidationProvider v-slot="{ errors }" rules="required" v-if="isInput">
												<b-form-group class="custom-values" :state="errors[0] ? false : null">
													<money
														:id="'price_unit-' + key"
														v-bind="money"
														v-model="product.unit_price"
														class="form-control"
														:class="errors[0] ? 'border-danger' : ''"
														@input="resolveUnitPrice(key)"
													/>
												</b-form-group>
											</ValidationProvider>

											<!-- PRICE -->
											<ValidationProvider v-slot="{ errors }" rules="required" v-if="isInput">
												<b-form-group class="custom-values" :state="errors[0] ? false : null">
													<money
														:id="'price-' + key"
														v-bind="money"
														v-model="product.price"
														class="form-control"
														:class="errors[0] ? 'border-danger' : ''"
														:readonly="true"
													/>
												</b-form-group>
											</ValidationProvider>
											<div>
												<b-button
													variant="danger"
													class="btn-icon btn-sm"
													style="margin-top: 3px"
													@click="removeProduct(key)"
												>
													<feather-icon icon="TrashIcon" />
												</b-button>
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</ValidationObserver>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
				<b-button v-if="isInput" variant="primary" @click="registerOperation">Guardar</b-button>
				<b-button v-if="!isInput" variant="primary" @click="registerOutput">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from "lodash"
import Ripple from "vue-ripple-directive"
import { Money } from "v-money"
import modalMixin from "@/mixins/modal.js"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import WarehouseService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"
import WeightService from "@/views/brain/administrative/views/weight-units/services/weight.service.js"
import ProviderService from "@/views/brain/administrative/views/providers/services/providers.service.ts"
import warehouseService from "@/views/amg/management/views/warehouse/services/warehouse.service.js"

import responsibleService from "@/views/amg/management/views/warehouse/services/responsibles.service.js"
import moment from "moment"
export default {
	props: {},
	directives: { Ripple },
	components: { Money },
	mixins: [modalMixin],
	data() {
		return {
			form: {
				code: null,
				type: "input",
				date: moment().format("YYYY-MM-DD"),
				provider: null,
				products: [],
			},
			operationTypes: [
				{ id: "input", label: "Ingreso" },
				{ id: "output", label: "Salida" },
			],
			responsibles: [],
			filteredOptions: [],
			measureUnits: [],
			providers: [],
			brands: [],
			money: {
				decimal: ",",
				thousand: ".",
				prefix: "S/ ",
				suffix: "",
				precision: 2,
				min: 0.0,
				masked: false,
			},
		}
	},
	computed: {
		getProducts() {
			return this.form.products
		},
		isInput() {
			return this.form.type == "input"
		},
	},
	async mounted() {
		this.toggleModal("modal-almacen-operation")
	},
	async created() {
		this.isPreloading()
		try {
			await Promise.all([this.getMeasureUnits(), this.getAllResponsibles(), this.getAllProviders()])
		} catch (error) {
			console.log("Error: ", error)
		} finally {
			this.isPreloading(false)
		}
	},
	methods: {
		async registerOperation() {
			const validate = await this.$refs.formAlmOperation.validate()
			if (!validate) return

			if (!this.form.products.length) {
				this.showToast("warning", "top-right", "Advertencia", "InfoIcon", "Agregue un producto")
				return
			}

			const { isConfirmed } = await this.showConfirmSwal({ text: "Registrar ingreso de stock." })
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const operations = []
				let isValidate = true
				let isDateValidate = true
				this.form.products.map((pro) => {
					if (!pro.count > 0) {
						isValidate = false
					}
					if (pro.expiration_date == null) {
						isDateValidate = false
					}
					operations.push({
						product_id: pro.id,
						expiration_date: pro.expiration_date,
						packaging_id: pro.packaging.weight_detail_id,
						quantity: Number(pro.count),
						total: pro.total,
						unit_price: Number(pro.unit_price),
						total_price: pro.price,
						brand_id: pro.brand,
					})
				})

				if (!isValidate) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"InfoIcon",
						"Algunos productos no tienen caitidad o precio ingresada."
					)
					return
				}
				if (!isDateValidate) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"InfoIcon",
						"Algunos productos no tienen fecha de vencimiento."
					)
					return
				}
				const params = {
					responsible_id: this.form.responsible_id,
					date: this.form.date,
					operations: operations,
					provider_id: this.form.provider,
				}
				const { data } = await WarehouseService.registerInputStock(params)
				this.showSuccessToast(data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error.response.data)
			} finally {
				this.isPreloading(false)
			}
		},

		async registerOutput() {
			const validate = await this.$refs.formAlmOperation.validate()
			if (!validate) return

			const { isConfirmed } = await this.showConfirmSwal({ text: "Registrar salida de stock." })
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const operations = []
				let isValidate = true
				this.form.products.map((pro) => {
					if (!Number(pro.count) > 0) {
						isValidate = false
					}
					operations.push({
						product_id: pro.id,
						quantity: Number(pro.count),
						packaging_id: pro.packaging.weight_detail_id,
						brand_id: pro.brand,
					})
				})
				if (!isValidate) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"InfoIcon",
						"Algunos productos no tienen caitidad ingresada."
					)
					return
				}

				const params = {
					responsible_id: this.form.responsible_id,
					date: this.form.date,
					operations: operations,
				}
				const { data } = await WarehouseService.registerOutputStock(params)
				this.showSuccessToast(data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		disableDates(date) {
			const inputDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
			const today = moment().startOf("day")
			return inputDate.isBefore(today)
			// return true
		},

		addProduct() {
			this.form.products.push({
				id: null,
				code: null,
				name: null,
				brand: null,
				expiration_date: null,
				description: null,
				current_stock: 0,
				quantity: 0,
				price: 0,
				packaging: 0,
				count: 0,
				packagings: [],
				brands: [],
				unit_price: 0,
				total: 0,
			})
		},

		async getAllResponsibles() {
			try {
				const { data } = await responsibleService.getResponsibles()
				this.responsibles = data
			} catch (error) {
				throw error
			}
		},

		async getAllProviders() {
			try {
				const { data } = await ProviderService.getProvidersSelect()
				this.providers = data
			} catch (error) {
				throw error
			}
		},

		async getBrandsByProduct(key, id) {
			try {
				const { data } = await WarehouseService.getBrandsByProduct(id)
				this.form.products[key].brands = data
			} catch (error) {
				throw error
			}
		},

		removeProduct(key) {
			const products = []
			this.form.products.map((row, index) => {
				if (index != key) {
					products.push(row)
				}
			})
			this.form.products = products
		},

		async selectHandler(key) {
			const item = this.form.products[key].prod
			if (item != null) {
				// const isExist = this.form.products.find((p) => p.id == item.id)
				// if (isExist) {
				// 	this.showToast(
				// 		"warning",
				// 		"top-right",
				// 		"Advertencia",
				// 		"InfoIcon",
				// 		"Producto ya se encuentra agregado"
				// 	)
				// 	this.form.products[key].prod = null
				// 	return
				// }
				const { id, code, name, measure_unit } = this.form.products[key].prod
				this.form.products[key].id = id
				this.form.products[key].code = code
				this.form.products[key].name = name
				this.form.products[key].measure_unit = measure_unit
				this.form.products[key].brands = []
				this.form.products[key].current_stock = null
				this.form.products[key].quantity = 0
				this.form.products[key].price = 0
				this.form.products[key].count = 0
				this.form.products[key].unit_price = 0
				this.form.products[key].brand = null
				this.form.products[key].expiration_date = null
				this.form.products[key].priceTotal = 0
				// this.getWeightUnitById(key, id)
				this.getBrandsByProduct(key, id)
				return
			}

			this.form.products[key].id = null
			this.form.products[key].code = null
			this.form.products[key].name = null
			this.form.products[key].measure_unit = null
			this.form.products[key].current_stock = 0
			this.form.products[key].quantity = 0
			this.form.products[key].price = 0
			this.form.products[key].brand = null
			this.form.products[key].packaging = null
			this.form.products[key].brands = []
			this.form.products[key].packagings = []
			this.form.products[key].unit_price = 0
		},

		searchItems: _.debounce(async function (search) {
			this.filteredOptions = []
			if (search.length < 1 || search == undefined) return
			const { data } = await WarehouseService.searchProducts({ search })
			this.filteredOptions = data.data
		}, 350),

		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},

		resolveUnitPrice(key) {
			if (key + 1 > this.form.products.length) {
				return
			}
			const quantity = this.form.products[key].count
			const price = this.form.products[key].unit_price

			if (!(quantity > 0) || !(price > 0)) {
				this.form.products[key].price = 0
				return
			}
			this.form.products[key].price = price * quantity
		},

		getPriceTotal(key) {
			if (!this.isInput) return
			const priceValue = this.form.products[key].unit_price
			const count = this.form.products[key].count

			this.form.products[key].price = priceValue * count
		},

		async getMeasureUnits() {
			try {
				const params = {
					type: "generic",
				}
				const { data } = await WeightService.getWeightForSelect(params)
				this.measureUnits = data.data
			} catch (error) {
				throw error
			}
		},

		async getWeightUnitById(key, id, brand) {
			try {
				const { data } = await responsibleService.getUnitWeightDetailByid(id, this.form.type, brand)
				this.form.products[key].packagings = data
			} catch (error) {
				throw error
			}
		},

		changeType() {
			this.form.products = []
			this.form.provider = null
			this.form.brand = null
		},

		changeBrand(key) {
			this.form.products[key].expiration_date = null
			this.form.products[key].packaging = null
			this.form.products[key].current_stock = null
			const brand = this.form.products[key].brand
			const { id } = this.form.products[key].prod
			this.getWeightUnitById(key, id, brand)
		},
		changeExpirationDate(key) {
			this.form.products[key].packaging = null
			this.form.products[key].current_stock = null
		},
		changePackagind(key) {
			const pack = this.form.products[key].packaging
			const brand = this.form.products[key].brand
			const expirationDate = this.form.products[key].expiration_date
			const item = this.form.products[key]
			if (pack) {
				const isExist = this.form.products.find(
					(p, index) =>
						p.id == item.id &&
						p.packaging.weight_detail_id == pack.weight_detail_id &&
						p.brand == brand &&
						p.expiration_date == expirationDate &&
						index != key
				)
				if (isExist) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"InfoIcon",
						"Producto ya se encuentra agregado"
					)
					this.form.products[key].packaging = null
					this.form.products[key].current_stock = null
					return
				}
				this.form.products[key].current_stock = this.form.products[key].packaging.current_balance
				return
			}
			this.form.products[key].current_stock = null
		},
	},

	// watch: {
	// 	form: {
	// 		deep: true,
	// 		handler(newProducts, oldProducts) {
	// 			newProducts.products.forEach((product, index) => {
	// 				if (product.prod?.measure_unit_id === oldProducts.products[index].prod?.measure_unit_id) {
	// 					// Realiza la petición específica para el producto actual
	// 					console.log(product.prod?.measure_unit_id)
	// 					product.prod?.measure_unit_id ? this.getWeightUnitById(product.prod?.measure_unit_id) : ""
	// 				}
	// 			})
	// 		},
	// 	},
	// },
}
</script>

<style lang="scss" scope>
.error-input {
	margin-bottom: 0;
	color: red;
	font-size: 12px;
	margin-top: 2px;
}

.custom-values {
	width: 110px;
	min-width: 110px;
	margin-left: 5px;
	margin-right: 5px;
}
.custom-values-out {
	width: 180px;
	min-width: 180px;
	margin-left: 5px;
	margin-right: 5px;
}
.custom-code {
	width: 100px;
	min-width: 100px;
	margin-left: 5px;
	margin-right: 5px;
}

.custom-code-out {
	width: 120px;
	min-width: 120px;
	margin-left: 5px;
	margin-right: 5px;
}

.custom-measure_unit {
	width: 180px;
	min-width: 180px;
	margin-left: 5px;
	margin-right: 5px;
}

.custom-measure_unit-out {
	width: 220px;
	min-width: 220px;
	margin-left: 5px;
	margin-right: 5px;
}
.custom-product {
	width: 450px;
	min-width: 450px;
	margin-left: 5px;
	margin-right: 5px;
}
.custom-product-out {
	width: 500px;
	min-width: 500px;
	margin-left: 5px;
	margin-right: 5px;
}
.container {
	overflow: auto;
}

.custom-scroll {
	overflow-y: scroll;
	min-height: 250px;
	max-height: 300px;
}
</style>
